.App {
  position: relative;
}

.App,
.login {
  color: white;
}

.nav-bar {
  position: fixed;
  right: 1rem;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
  transition: 300ms all ease-out;
}

.nav-bar.center{
  bottom: 50% !important;
  transform: translateY(50%);
}

.nav-bar > * {
  margin-bottom: 2rem;
}

.nav-bar > hr {
  background-color: #fff;
  opacity: 0.7;
  width: 100%;
  height: 2px;
  margin-top:-1px;
}

.nav-item {
  color: #fff;
  cursor: pointer;
  opacity: 0.7;
  transition: 300ms all ease-out;
}

.nav-item > * {
  height: 40px !important;
  width: 40px !important;
}

.nav-item:hover {
  opacity: 1;
  transform: scale(1.2);
}

@media screen and (max-width: 768px){
  .nav-bar > * {
    margin-bottom:1rem;
  }

  .nav-item > * {
    height: 25px !important;
    width: 25px !important;
  }
}

/** Global CSS **/
section {
  font-size: 1rem;
  margin: 2rem 4rem;
}

section + section {
  margin-top: 8rem;
}

section.paper {
  border-radius: 1rem;
  background-color: #242527;
  padding: 1rem 2rem;
  box-shadow: 0 0 2rem 0rem #86bbd8, 0 0 0.2rem 0.2rem rgba(0, 0, 0, 0.8);
}

h1.section-header {
  text-align: center;
  margin: 0;
  font-size: 2rem;
  text-transform: uppercase;
}

h1.section-header::before {
  content: "< ";
}

h1.section-header::after {
  content: " />";
}

z-1 {
  z-index: 1;
}

.divider {
  width: 50%;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid white;
}

.container {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.container + .container {
  margin-left: 1rem;
}

/* Hover effect over header to show linear gradient*/
/* h1:hover,
h1:focus{
  background: linear-gradient(to bottom, #1AE9CA, #FAF102);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.app-body {
  position: relative;
  max-width: 70rem;
  margin: auto;
}

@media screen and (max-width: 768px) {
  section {
    margin: 2rem;
  }
  h1.section-header {
    font-size: 1.5rem;
  }
}
/**** LOGIN ****/
.top-adornment,
.right-adornment,
.left-adornment,
.bottom-adornment {
  position: absolute;
}

.top-adornment,
.bottom-adornment {
  width: 30%;
  border-top: 1px white solid;
}

.left-adornment,
.right-adornment {
  height: 30%;
  border-right: 1px white solid;
}

.top-adornment {
  right: -1rem;
  top: 0;
}

.bottom-adornment {
  left: -1rem;
  bottom: 0;
}

.left-adornment {
  left: 0;
  bottom: -1rem;
}
.right-adornment {
  right: 0;
  top: -1rem;
}

.slash {
  position: fixed;
  width: 75%;
  border-top: 1px white solid;
  transform: rotate(-36deg);
  left: 13%;
  top: 49.6%;
  z-index: 0;
}

svg.loading {
  animation: blink 1s infinite ease-out;
}

@keyframes blink {
  0% {
    color: white;
  }

  100% {
    color: #86bbd8;
  }
}

.ripples {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  background-color: #86bbd8;
  opacity: 0.75;
  transform: scale(0);
  text-shadow: 0 0 black;
}

.ripples.loading {
  animation: ripple 1s infinite ease-out;
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}

/**** HERO Page ****/
.hero,
.login {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.login {
  align-items: center;
}

.hero > .name {
  margin: 0;
  text-transform: initial;
}

.hero > .first-name {
  width: 80%;
  border-bottom: 3px solid white;
}

.hero > .last-name {
  width: 50%;
  border-top: 3px solid white;
  padding-top: 0.7rem;
}

.font-light {
  font-weight: 100;
}

.font-heavy {
  font-weight: bold;
}

.hero > .position {
  margin: 1rem 0rem 0rem 0rem;
  font-size: 2rem;
}

.hero > h1 {
  font-size: 4.5rem;
}
.hero > h2 {
  font-size: 2rem;
}
.hero > h3 {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .hero > h1 {
    font-size: 3.5rem;
  }
  .hero > h2 {
    font-size: 1.8rem;
  }
  .hero > h3 {
    font-size: 0.9em;
  }
}

/**** ABOUT ME ****/
.about-me-content {
  font-size: 1.2rem;
  font-weight: 200;
  text-align: left;
}

.about-me-content > span {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .about-me-content {
    font-size: 1rem;
  }
}

/**** EDUCATION ****/
.education-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.education {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.education-details > .name {
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem 0rem 0rem 0rem;
}
.education-details > .grade {
  font-weight: 200;
  margin: 0.1rem 0rem 0rem 0rem;
}
.education-details > .organisation {
  font-weight: inital;
  font-size: 0.9rem;
  margin: 0.1rem 0rem 0rem 0rem;
}

.education-img {
  font-size: 3rem;
  border-radius: 100%;
  font-size: 3rem;
  width: 6rem;
  border: 0.2rem solid white;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.education:hover > .education-img {
  animation: blink-border 2s infinite linear;
  animation-delay: 300ms;
}

.education:hover > .education-img > svg {
  animation: turn 2s infinite linear;
  animation-delay: 300ms;
}

@keyframes turn {
  50% {
    transform: rotate3d(0, 1, 0, 360deg);
    color: #86bbd8;
    border-color: #86bbd8;
  }
}

@keyframes blink-border {
  50% {
    border-color: #86bbd8;
  }
}

.education-details {
  font-size: 0.8rem;
}

@media screen and (max-width: 768px) {
  .education-container {
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
  }
  .education:hover > .education-img > svg {
    animation: none;
    animation-delay: none;
  }
}

/**** EMPLOYMENT ****/
.employment-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 50%;
}

.employment-container::after {
  content: "";
  width: 5rem;
  height: 1px;
  border-top: 2px white solid;
}

.employment-date {
  padding: 0rem 1rem;
}

.employment-responsibilities {
  padding: 1rem 0rem;
  width: 50%;
}

.employment-responsibilities > span {
  font-weight: bold;
  padding: 0rem 1rem;
  display: inline-block;
}

.employment-responsibilities > ul {
  padding: 0rem 3rem 1rem 3rem;
}

.employment-timeline {
  display: flex;
  flex-direction: column;
  position: relative;
}

.employment-timeline::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  left: 50%;
  border: 1px solid white;
  background-color: white;
}

.timeline-item {
  display: flex;
  min-height: 200px;
}

.employment-timeline > .timeline-item:nth-child(2n) {
  flex-direction: row-reverse;
}

.employment-timeline > .timeline-item:nth-child(2n) > .employment-container {
  flex-direction: row-reverse;
  border-right: none;
}

.employment-img {
  background-color: #212324;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0px 1px 0px -2px rgba(255, 255, 255, 0.2),
    0px 1px 8px 0px rgba(255, 255, 255, 0.12);
  height: 100px;
  width: 100px;
  max-width: 15rem;
  transition: all 300ms ease-out;
}

.employment-img:hover {
  box-shadow: 0px 3px 3px -2px rgba(255, 255, 255, 0.2),
    0px 1px 8px 0px rgba(255, 255, 255, 0.12);
  transform: scale(1.1);
}

@media screen and (max-width: 768px) {
  .timeline-item {
    flex-direction: column !important;
  }

  .employment-container,
  .employment-responsibilities {
    width: 100%;
  }

  .employment-container {
    flex-direction: row-reverse;
  }

  .employment-date {
    margin-top: 3rem;
    font-size: 0.8rem;
  }

  .employment-img {
    z-index: 2;
  }

  .employment-responsibilities > ul {
    padding: 0rem 3rem;
  }

  .employment-timeline::after {
    left: 0;
  }

  .employment-container::after {
    position: absolute;
    top: 50%;
    width: 60%;
    max-width: 15rem;
  }
}
/**** SKILL ****/
.skill-container {
  font-weight: bold;
  font-size: 1.2rem;
}
.skill + .skill {
  margin-top: 0.5rem;
}
.skill {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 1rem;
  text-align: left;
}

.skill-bar {
  width: 80%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-columns: 1fr;
  height: 1rem;
  align-items: center;
}

.skill-bar + .skill-bar {
  margin-top: 2rem;
}

.regions {
  border-right: 1px white solid;
  height: 50%;
  width: 100%;
  box-sizing: content-box;
  padding: 4px 0 4px 0;
  z-index: 2;
}

.regions:nth-child(4) {
  border: none;
}

.bar {
  width: 100%;
  height: 50%;
  position: absolute;
  left: 0;
  border: 2px solid #fff;
  border-radius: 2rem;
  box-shadow: 0 0 4px 1px rgba(255, 255, 255, 0.4);
}

.bar-size {
  width: 75%;
  height: 100%;
}

.bar-fill {
  animation: fillBar 2s ease-out 2s;
  background-color: green;
  height: 100%;
}

@keyframes fillBar {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
/**** ACHIEVEMENTS ****/
.achievement-container {
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-gap: 1rem;
}

.achievement-container + .achievement-container {
  padding-top: 1rem;
}

.achievement > .organisation {
  font-size: 0.9rem;
}

.achievement > .organisation::before {
  content: " - ";
}

/**** Drawer & Modal css ****/
.admin-item > .action-icon {
  opacity: 0;
}
.admin-item:hover {
  color: #86bbd8;
}

.admin-item:hover > .action-icon {
  opacity: 0.3;
}

.visibility-icon,
.delete-icon {
  color: white;
  padding: "0.5rem";
  opacity: 0.3;
}
.visibility-icon:hover {
  color: #336699;
}

.delete-icon:hover {
  color: red;
}
